import React, {Fragment} from 'react'
import Image from 'gatsby-image'
import {graphql} from 'gatsby'
import {OutboundLink} from 'gatsby-plugin-google-analytics'

import SEO from '../components/seo'
import Layout from '../containers/layout'

// When I spake to a reqruiter (Debbie) she gave me the below tips, I've integrated some of them, but it's worth considering again next time I update my resume.

// How I'm using react, make note of the complexity. Migrating from angular, react, indicates complexity. best practise meetings (not js-meeting). Unit test should be added and tech, jest. example of the kinds of features I've been building. Indicate what's going on. Performance is always good so maybe bundle size work (I guess I should stop using the vague tech-debt and be specific). Stake holder management, non at accelo but I could draw in Rail experience, how did I manage difficult people write something down, about how I handled it well or in reflection I could have done it better. have stories about what I learned.

// Accessibility stuff.
// Description of what Accelo is, for context of the reader. Project management platform that integrates things for professional services.

export const query = graphql`
  query ResumePageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
      headshot {
        asset {
          fluid(maxWidth: 250, maxHeight: 250) {
              ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const jobs = [
  {
    name: 'Proprietary Trading',
    dates: '(May 2020 to Present)',
    jobTitle: 'Software developer',
    description: <p>UI design and development</p>
  },
  {
    name: 'Accelo',
    href: 'https://www.accelo.com/',
    dates: '(July 2018 to May 2020)',
    jobTitle: 'Frontend Developer',
    description: <p>Accelo is a business automation platform, It automates much of the internal processes of professional services businesses, from email capture and ticketing to project management</p>,
    highlights: [
      `Working in the architecture team. Heavily involved in the adoption of new tech (React, Typescript, GraphQL and Apollo node.js and client, Redux, Jest).`,
      `Worked effectively within a large decades-old code base with plenty of challenges from legacy code`,
      `Lead our cross-team frontend best practices meetings`,
      `Was given responsibilities of a "trusted code reviewer" after a few months of starting with accelo`,
      `Lead several small projects focused on technical debt`,
      <span>I represented Accelo, presenting a <OutboundLink className='underline' href='https://www.youtube.com/watch?v=doGsUKjxdiU&feature=youtu.be'> talk about the JS Abstract Syntax Tree</OutboundLink></span>
    ],
    longForm: <>
      <p>
        I kicked off discussions for and then lead react adoption.
        Involving a feasibility stage comparing other frameworks, namely could they work along side our existing codebase (mostly AngularJS). Then setting up the framework, educating the team and building out abstractions of interplay between react and angularJS to reduce the effective complexity for the team and make migration easier.
      </p>
      <p>
        A major project I worked on was building the UI for a dynamic table page that allowed users to pick both columns and filters for the table.
        We used GraphQL to fetch data, and In order to do this in a data driven way we built a query builder to dynamically fetch columns, leveraging features of the tech like fragments and directives in order to let graphlQL do the heavy lifting leaving our code as simple as possible.
      </p>
    </>
  },
  {
    name: 'Globetech',
    href: 'http://www.globetech.com.au/',
    dates: '(March 2015 to June 2018)',
    jobTitle: 'Systems Engineer',
    highlights: [
      `Maintenance and further development of a legacy code-base (niche languages dataTaker and Cicode) in a zero tolerance for failure safety environment`,
      `On call duties, trouble shooting systems in real-time while advising stake holders, preforming under pressure`,
      `Maintain crucial safety monitoring network, with 400 sensors and 50 smart devices over 4km and an annual budget of $600k`,
      `Weekly reports, monthly estimates and annual audit,`,
      `Varies non-technical responsibilities: Reporting to an external safety committee, communication of technical concepts to non-technical professionals, Project management and budgeting`
    ],
    longForm: <>
      <p>
        I oversaw a large safety monitoring network for a critical piece of rail infrastructure. The role involved a wide spread of responsibilities.
        Physical maintenance of system involving coordination with our trade persons and rail protection officers. Updates and extensions to the monitoring software.
        Project management for extensions to the system and multiple levels of reporting and meetings to meet regulatory requirements.
      </p>
      <p>
        One of the most challenging parts of the role was stakeholder management as the work involved both the company employing our services, as well as multiple other third parties which at times involved high tensions when trying to get the root mistakes when they happened.
        The most valuable lessons I learnt during my time at Globetech is the importance of actively communicating before it was necessary and recording key decisions as they can help guide tense conversation in a positive direction, keeping the discussion objective.
      </p>
    </>
  }
  // {
  //   name: '',
  //   href: '',
  //   dates: '()',
  //   jobTitle: '',
  //   highlights: [
  //     ``,
  //   ]
  // },
]

const freelance = [
  {
    name: 'Underground Civil website',
    href: 'https://www.undergroundcivil.com.au/',
    dates: '2019',
    highlights: [
      <span>Content website, built with <OutboundLink href='https://www.gatsbyjs.org/'>Gatsby</OutboundLink></span>,
      <span><OutboundLink href='sanity.io'>Sanity.io</OutboundLink> headless CMS integration</span>,
      <span><OutboundLink href='https://developers.google.com/maps/documentation/javascript/reference/map'>Google maps</OutboundLink> integration</span>
    ]
  },
  {
    name: 'PORTABOOM - Embedded programming',
    href: 'http://www.trafficaccess.com.au/portaboom',
    dates: '2014',
    highlights: [
      `Developed firmware for an award-winning portable boom gate for D&D Traffic Management`,
      <span>It has been used on over 4000 work-sites, and the D&D has reported a <OutboundLink href='https://www.dd-group.com.au/portaboomr-sets-a-new-benchmark-in-safety/'>90% reduction in related safety incidents</OutboundLink></span>
    ]
  }
]

const sideGigs = [
  {
    name: 'VegeBoxes',
    href: 'https://vegeboxes.com/',
    dates: '2019',
    highlights: `React, Gatsby, Google maps, AWS lambda served Apollo GraphQL.`
  },
  {
    name: 'Single Product Store',
    href: 'https://singleproduct.store',
    dates: '2019',
    highlights: `React, Gatsby, Cloudinary, AWS lambda served Apollo GraphQL.`
  },
  {
    name: 'My blog',
    href: 'https://kurthutten.com/',
    dates: '2019',
    highlights: `React, Gatsby, Sanity.`
  },
  {
    name: 'MRI volume slice', // a brain image viewer
    href: 'https://kurthutten.com/blog/mri-volume-slice/',
    extraDetail: <span>a brain image viewer</span>,
    dates: '2019',
    highlights: `Vanilla javascript, canvas.`
  },
  {
    name: '8-bit sinusoidal PWM generation',
    href: 'https://www.youtube.com/watch?v=Pf2Jx5LMXH0',
    extraDetail: <OutboundLink href='https://github.com/Irev-Dev/Arduino-Atmel-sPWM' className='text-indigo-600 border-b-4 border-indigo-400'>Repo</OutboundLink>,
    dates: '2019',
    highlights: `Arduino, interrupt-driven C programming`
  },
  {
    name: 'Rounding library for OpenSCAD',
    href: 'https://github.com/Irev-Dev/Round-Anything',
    dates: '2019',
    highlights: `3D CAD programming language`
  },
  {
    name: 'Drone designed in OpenSCAD',
    href: 'https://lucy-quad.kurthutten.com/',
    extraDetail: <OutboundLink href='https://github.com/Irev-Dev/Lucy' className='text-indigo-600 border-b-4 border-indigo-400'>Model</OutboundLink>,
    dates: '2019',
    highlights: `3D CAD programming language`
  }
  // {
  //   name: '',
  //   href: '',
  //   dates: '2019',
  //   highlights: ``
  // },
]

const skills = [
  {name: `React`, href: `https://reactjs.org/`},
  {name: `Redux`, href: `https://redux.js.org/`},
  {name: `Vue`, href: `https://vuejs.org/`},
  {name: `Typescript`, href: `https://www.typescriptlang.org/`},
  {name: `CSS and HTML`, href: ``},
  {name: `Node`, href: `https://nodejs.org/en/`},
  {name: `Docker`, href: `https://www.docker.com/`},
  {name: `Jest`, href: `https://jestjs.io/`},
  {name: `Git`, href: `https://git-scm.com/`},
  {name: `Sanity (headless CMS)`, href: `https://www.sanity.io/`},
  {name: `Apollo (client and server)`, href: `https://www.apollographql.com/`},
  {name: `Nexus (Code-First GraphQL Schemas)`, href: `https://nexus.js.org/`},
  {name: `Serverless framework (mostly used for AWS lamda)`, href: `https://www.serverless.com/`},
  {name: `AST tools (EsLint and estraverse)`, href: ``},
  {name: `AngularJS`, href: `https://angularjs.org/`},
  {name: `DynamoDB`, href: `https://aws.amazon.com/dynamodb/`},
  {name: `Gatsby (This website uses Gatsby)`, href: `https://www.gatsbyjs.org/`}
]

export default function (props) {
  const {data, errors} = props

  return (
    <Layout>
      <SEO title='resume' description="Kurt Hutten's professional resume" keywords={['resume', 'industrial automation', 'trading', 'frontend', 'developer', 'design', 'ui', 'web', 'engineer', 'mechatronics', 'software', 'embeded']} />
      <div className='mx-auto max-w-3xl text-lg'>
        <div className='max-w-6xl mx-auto shadow-lg bg-indigo-900 text-indigo-300 mt-20'>
          <div className='md:flex z-10 relative py-10 px-10'>
            <div className='flex-initial mb-10 md:m-0'>
              <Image
                fluid={data.site.headshot.asset.fluid}
                className='rounded-full mx-auto h-32 w-32'
              />
            </div>
            <div className='flex-1 flex items-center px-10'>
              <div>
                <h1 className='text-xl font-medium mb-4 text-indigo-200'>Hey I'm Kurt</h1>
                <div className='font-lora'>
                  You probably got here from my linkedin profile.
                  The resume below may or may not be current. Feel free to take a look but please note that I'm not currently looking for work.
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className='font-bold text-2xl mt-16 tracking-widest'>Experience</h2>
        {jobs.map(({name, href, dates, jobTitle, highlights, longForm, description}, index) => (
          <div className='font-lora shadow-md bg-indigo-100 p-12 mt-3 mb-10' key={index}>
            <div>{href ? <OutboundLink href={href} className='text-indigo-600 border-b-4 border-indigo-400'>{name}</OutboundLink> : name } {dates} - {jobTitle}</div>
            <div className='text-base text-gray-700'>{description}</div>
            <ul className='ml-6 relative'>
              {highlights && highlights.map((highlight, index2) => (
                <li className='my-2' key={index2}><span className='absolute -ml-4 text-sm'>-</span>{highlight}</li>
              ))}
            </ul>
            <div className='text-gray-700'>
              {longForm}
            </div>
          </div>
        ))}
        <h2 className='font-bold text-2xl mt-16 tracking-widest'>Freelance Experience</h2>
        {freelance.map(({name, href, dates, highlights}, index) => (
          <div className='font-lora shadow-md bg-indigo-100 p-12 mt-3 mb-10' key={index}>
            <div><OutboundLink href={href} className='text-indigo-600 border-b-4 border-indigo-400'>{name}</OutboundLink> - {dates}</div>
            <ul className='ml-6 relative'>
              {highlights.map((highlight, index2) => (
                <li className='my-2' key={index2}><span className='absolute -ml-4 text-sm'>-</span>{highlight}</li>
              ))}
            </ul>
          </div>
        ))}
        <h2 className='font-bold text-2xl mt-16 tracking-widest'>Passion/Side Projects</h2>
        <div className='font-lora shadow-md bg-indigo-100 p-12 mt-3 mb-10'>
          {sideGigs.map(({name, href, highlights, extraDetail}, index) => {
            return (
              <Fragment key={index}>
                <div className='mt-4'><OutboundLink href={href} className='text-indigo-600 border-b-4 border-indigo-400'>{name}</OutboundLink>{extraDetail && ' - ' }{extraDetail}</div>
                <div className='ml-6 mt-2'>{highlights}</div>
              </Fragment>
            )
          })}
        </div>
        <h2 className='font-bold text-2xl mt-16 tracking-widest'>Education</h2>
        <div className='font-lora shadow-md bg-indigo-100 p-12 mt-3 mb-10'>
          <p>
            Bachelor of Engineering Honours
          </p>
          <div className='ml-6'>
            <div>
              Class II Division 1
            </div>
            <div>
              Mechatronic Engineering
            </div>
            <div>
              <OutboundLink className='text-indigo-600 border-b-4 border-indigo-400' href='https://www.uow.edu.au/'>University of Wollongong</OutboundLink>, 2014
            </div>
          </div>
          <p>
            Honours thesis was in novel segmenting techniques for brain tissue in fMRI data, using MATLAB.
          </p>
        </div>
        <h2 className='font-bold text-2xl mt-16 tracking-widest'>Skills</h2>
        <div className='font-lora shadow-md bg-indigo-100 p-12 mt-3 mb-10'>
          <ul>
            {skills.map(({name, href}, index) => (
              <li key={index}>- {href ? <OutboundLink href={href}>{name}</OutboundLink> : name}</li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}
